import React, { Fragment, ReactNode } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import BootstrapTable, {
  ColumnDescription,
  SortOrder,
} from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import { ThemeColors } from "src/constants/colors";
import BaseTableCard from "./BaseTableCard";
import TableHeader from "./TableHeader";
import Select from "react-select";
import styled from "styled-components";
import { ICustomAction } from "src/interfaces/general";

interface ITablePage {
  loading?: boolean;
  metaTitle: string;
  title: string;
  description?: string;
  data: Array<any>;
  columns: ColumnDescription<any, any>[];
  hasPermission?: boolean;
  additionalHeader?: ReactNode;

  // functions
  onAdd?: () => void;
  onExport?: () => void;
  onImport?: () => void;
  customAction?: ICustomAction;
  footer?: ReactNode;
  modal?: ReactNode;

  // pagination
  pageSize?: number;
  totalSize?: number;
  defaultSorted?: any;

  // alert
  confirmAlert?: {
    visible?: boolean;
    title?: string;
    description?: string;
    onConfirm: () => void;
    onCancel?: () => void;
  };
  successAlert?: {
    visible?: boolean;
    title?: string;
    onConfirm: () => void;
  };
  // Searchable select
  siteName?: string;
  handleChange?: (selectedOption: any) => void;
  siteList?: any;
}

const TablePage = (props: ITablePage) => {
  const { t } = useTranslation();
  return (
    <BaseTableCard
      loading={props.loading}
      metaTitle={props.metaTitle}
      footer={
        <>
          {props.footer}
          {props.confirmAlert?.visible ? (
            <SweetAlert
              title={props.confirmAlert.title || t("alert.confirm")}
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={props.confirmAlert.onConfirm}
              onCancel={props.confirmAlert.onCancel}
            >
              {props.confirmAlert.description || t("confirmAlert")}
            </SweetAlert>
          ) : null}

          {props.successAlert?.visible ? (
            <SweetAlert
              success
              title={props.successAlert.title || t("alert.deleted")}
              onConfirm={props.successAlert.onConfirm}
            />
          ) : null}
        </>
      }
      modal={<>{props.modal}</>}
    >
      <PaginationProvider
        pagination={paginationFactory({
          sizePerPage: props.pageSize,
          totalSize: props.totalSize,
          custom: true,
        })}
      >
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField="id"
            data={props.data}
            columns={props.columns}
            bootstrap4
            search
          >
            {toolkitProps => (
              <React.Fragment>
                <TableHeader
                  title={props.title}
                  description={props.description || ""}
                  hasPermission={props.hasPermission || false}
                  onAdd={props.onAdd}
                  onExport={props.onExport}
                  onImport={props.onImport}
                  customAction={props.customAction}
                  searchProps={toolkitProps.searchProps}
                  addtional={props.additionalHeader}
                  loading={props.loading}
                />
                {props.title == "Products List" && (
                  <Row>
                    <Col md={6} className="pb-3">
                      <Select
                        value={
                          props.siteName
                            ? {
                              value: props.siteName,
                              label: props.siteName,
                            }
                            : null
                        }
                        onChange={props.handleChange}
                        options={
                          props.siteList.length > 0
                            ? props.siteList
                            : [{ value: "Loading", label: "Loading" }]
                        }
                        isSearchable={true}
                      />
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col xl="12">
                    <div className="table-responsive">
                      <BootstrapTable
                        {...toolkitProps.baseProps}
                        {...paginationTableProps}
                        // selectRow={selectRow}
                        defaultSorted={props.defaultSorted}
                        classes={"table align-middle table-nowrap table-hover"}
                        bordered={false}
                        striped={false}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="align-items-md-center mt-30">
                  <Col className="pagination pagination-rounded justify-content-end mb-2">
                    <PaginationListStandalone {...paginationProps} />
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>
    </BaseTableCard>
  );
};

export default TablePage;

export const makeColumns = (
  onDelete: ((item: any) => void) | null,
  onEdit: ((item: any) => void) | null,
  header: any[],
  extra?: {
    icon: string;
    onClick: (item: any) => void;
  },
  deleteButtonDisable?: boolean
) => {
  const index = header.findIndex(item => item.text === "Action");
  if (index === -1) return header;
  header[index].formatter = (_: any, item: any) => (
    <div className="flex-d row">
      {onEdit ? (
        <ActionButton danger={false} onClick={() => onEdit(item)}>
          <i className="mdi mdi-pencil" id="edittooltip"></i>
        </ActionButton>
      ) : null}
      {onDelete ? (
        <Fragment>
          {!deleteButtonDisable && (
            <ActionButton danger onClick={() => onDelete(item)}>
              <i className="mdi mdi-delete" id="deletetooltip"></i>
            </ActionButton>
          )}
        </Fragment>
      ) : null}
      {extra ? (
        <ActionButton danger={false} onClick={() => extra?.onClick(item)}>
          <i
            className={`mdi mdi-${extra.icon} font-size-18`}
            id="deletetooltip"
          />
        </ActionButton>
      ) : null}
    </div>
  );
  return header;
};

const ActionButton = styled.div<{ danger: boolean }>`
  width: 24px;
  height: 24px;
  color: white;
  background-color: ${({ danger }) =>
    danger ? ThemeColors.red : ThemeColors.main};
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin: 2px;
  cursor: pointer;
  display: flex;
  font-size: 14px;
`;
