import { IMenu } from "./SidebarContent";
import { Permissions } from "src/enum/Permission";

export const MenuList: IMenu[] = [
    { title: "Dashboard", link: "/dashboard", icon: "home", permission: "admins" },
    { title: "Sites", link: "/sites", icon: "layers", permission: "sites" },
    {
        title: "Admins", icon: "user", permission: "admins",
        subMenu: [
            { name: "List", permission: "admins", link: "/admins" },
            { name: "Score", permission: "admins", link: "/admins/scores/all" },
        ]
    },
    { title: "Customers", link: "/customers", icon: "users", permission: "customers" },
    { title: "Bookings", link: "/bookings", icon: "book", permission: "bookings" },
    { title: "Vehicles", link: "/vehicles", icon: "truck", permission: "vehicles" },
    {
        title: "Products", icon: "database", permission: "products",
        subMenu: [
            { name: "Dashboard", permission: "products", link: "/products/dashboard" },
            { name: "List", permission: "products", link: "/products" },
            { name: "Settings", permission: "products", link: "/products/settings" },
        ]
    },
    { title: "Sale of Products", link: "/sale-of-products", icon: "filter", permission: "saleOfProducts" },
    { title: "GeoFence", link: "/geo-fence", icon: "map-pin", permission: "geofence" },
    { title: "Warranty & Recovery", link: "/warranty", icon: "file", permission: "warranty" },
    // {
    //     title: "Checkins", icon: "check", permission: "checkins",
    //     subMenu: [
    //         { name: "List", permission: "List", link: "/checkins" },
    //         { name: "Settings", permission: "Settings", link: "/checkins/settings/all" },
    //     ]
    // },
    {
        title: "Notifications", icon: "bell", permission: "notifications",
        subMenu: [
            { name: "List", link: "/notifications", permission: "notifications" },
            { name: "Templates", link: "/notifications/templates", permission: "notifications" },
        ]
    },
    // { title: "Keylocker", link: "/keylocker", icon: "key", permission: "keylocker" },
    { title: "Comms", link: "/comms", icon: "command", permission: "comms" },
    { title: "BookingTool", link: "/booking-tool", icon: "tool", permission: "bookingTool" },
    // { title: "Directories", link: "/directories", icon: "folder", permission: "directories" },
    { title: "Roles", link: "/roles", icon: "check-circle", permission: "roles" },
    { title: "Groups", link: "/groups", icon: "grid", permission: "groups" },
    {
        title: "Settings", icon: "settings", permission: "settings",
        subMenu: [
            { name: "Onboarding", permission: "List", link: "/settings/onboarding" },
            { name: "Makes", permission: "make", link: "/settings/makes" },
        ]
    },
];

export const AdminMenuList: IMenu[] = [
    { title: "Dashboard", link: "/dashboard", icon: "home", permission: "dashboard" },
    { title: "Companies", link: "/companies", icon: "folder", permission: "companies" },
    { title: "Sites", link: "/sites", icon: "layers", permission: "sites" },
    {
        title: "Admins", icon: "user", permission: "admins",
        subMenu: [
            { name: "List", permission: "List", link: "/admins" },
            { name: "Score", permission: "Score", link: "/admins/scores/all" },
        ]
    },
    { title: "Customers", link: "/customers", icon: "users", permission: "customers" },
    { title: "Bookings", link: "/bookings", icon: "book", permission: "bookings" },
    { title: "Vehicles", link: "/vehicles", icon: "truck", permission: "vehicles" },
    {
        title: "Products", icon: "database", permission: "products",
        subMenu: [
            { name: "Dashboard", permission: "products", link: "/products/dashboard" },
            { name: "List", permission: "products", link: "/products" },
            { name: "Settings", permission: "products", link: "/products/settings" },
        ]
    },
    { title: "Sale of Products", link: "/sale-of-products", icon: "filter", permission: "sale of products" },
    { title: "GeoFence", link: "/geo-fence", icon: "map-pin", permission: "geofence" },
    { title: "Warranty & Recovery", link: "/warranty", icon: "file", permission: "warranty" },
    {
        title: "Checkins", icon: "check", permission: "checkins",
        subMenu: [
            { name: "List", permission: "List", link: "/checkins" },
            { name: "Settings", permission: "Settings", link: "/checkins/settings/all" },
        ]
    },
    {
        title: "Notifications", icon: "bell", permission: "notifications",
        subMenu: [
            { name: "List", link: "/notifications", permission: "notifications" },
            { name: "Templates", link: "/notifications/templates", permission: "notifications" },
        ]
    },
    { title: "Keylocker", link: "/keylocker", icon: "key", permission: "keylocker" },
    { title: "Comms", link: "/comms", icon: "command", permission: "comms" },
    { title: "BookingTool", link: "/booking-tool", icon: "tool", permission: "bookingTool" },
    { title: "Directories", link: "/directories", icon: "folder", permission: "directories" },
    { title: "Roles", link: "/roles", icon: "check-circle", permission: "roles" },
    { title: "Groups", link: "/groups", icon: "grid", permission: "groups" },
    {
        title: "Settings", icon: "settings", permission: "settings",
        subMenu: [
            { name: "Onboarding", permission: "List", link: "/settings/onboarding" },
            { name: "Makes", permission: "make", link: "/settings/makes" },
        ]
    },
];